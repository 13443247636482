import { IS_STICKY, IS_MINIMIZED, ANIMATION } from '../constants';

export default function stickyElement(element: string) {
    const elm: HTMLDivElement | null = document.querySelector(element);

    if (elm) {
        const threshold: number | undefined = elm.getBoundingClientRect()?.top;
        toggleElement(elm, threshold);

        window.addEventListener('scroll', (e: Event) => {
            toggleElement(elm, threshold);
        });

        window.addEventListener('resize', (e: Event) => {
            toggleElement(elm, threshold);
        });
    }
}

function toggleElement(elm: HTMLDivElement, threshold: number | undefined): void {
    const header: HTMLDivElement | null = document.querySelector('.hd__cti');
    const headerModules: HTMLDivElement | null | undefined = header?.querySelector('.hd__modules');
    const offset: number | undefined = document.querySelector('html')?.scrollTop;
    const elmOffsetLeft: number | undefined = elm.getBoundingClientRect()?.left;
    const elmWidth: number | undefined = elm.getBoundingClientRect().width;
    const headerOffset: DOMRect | undefined = header?.getBoundingClientRect();

    if (headerOffset) {
        const offsetLeft = headerOffset.left && headerOffset.width && elmWidth ? headerOffset.left + headerOffset.width - elmWidth : elmOffsetLeft;

        if (threshold && elmWidth && offsetLeft) {
            if (headerOffset.height) {
                threshold = threshold - headerOffset.height;
            }

            if (offset && offset > threshold) {
                elm.style.left = `${offsetLeft}px`;
                elm.style.width = `${elmWidth}px`;
                elm.classList.add(IS_STICKY);
                headerModules?.classList.remove(ANIMATION.FADE_IN_FAST);
                headerModules?.classList.add(ANIMATION.FADE_OUT_FAST);
                toggleClasOnParentElement(elm);
            } else {
                elm.style.left = ``;
                elm.style.width = ``;
                elm.classList.remove(IS_STICKY);
                headerModules?.classList.remove(ANIMATION.FADE_OUT_FAST);
                headerModules?.classList.add(ANIMATION.FADE_IN_FAST);
                toggleClasOnParentElement(elm);
            }
        }
    }
}

function toggleClasOnParentElement(elm: HTMLDivElement) {
    const elmParent: HTMLElement | null | undefined = elm.parentElement?.parentElement;
    const elmGrandparent: HTMLElement | null | undefined = elmParent?.parentElement;

    if (elmGrandparent?.classList.contains('profile')) {
        if (elm.classList.contains(IS_STICKY)) {
            elmParent?.classList.add(IS_MINIMIZED);
            elmGrandparent?.classList.add(IS_MINIMIZED);
        } else {
            elmParent?.classList.remove(IS_MINIMIZED);
            elmGrandparent?.classList.remove(IS_MINIMIZED);
        }
    }
}
