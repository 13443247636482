import { IS_ACTIVE, IS_VISIBLE, POPOUT_TIMEOUT, DIRECTION_BOTTOM, DIRECTION_TOP, DIRECTION_LEFT, DIRECTION_RIGHT, EVENT } from '../constants';
import { popoutMenuActionType, ElementIsOutProperties } from '../typings';

const directions: string[] = [DIRECTION_BOTTOM, DIRECTION_TOP, DIRECTION_LEFT, DIRECTION_RIGHT];
const ANALYTICS_CATEGORY = 'Popout Menu';
const ANALYTICS_ACTION_ATTRIBUTE = 'aria-label';

const popoutMenu = (selector: HTMLDivElement, subnav: HTMLDivElement, actionType: popoutMenuActionType = 'click'): void => {
    onClick(selector, subnav);
    onClickOutside(selector, subnav);
    onItemClick(selector, subnav);

    if (actionType === 'hover') {
        onHover(selector, subnav);
    }
};

const onHover = (selector: HTMLDivElement, subnav: HTMLDivElement): void => {
    const popoutMenuElements: HTMLDivElement[] = [selector, subnav];
    let timeout: ReturnType<typeof setTimeout>;

    selector.addEventListener('mouseenter', (e: Event): void => {
        e.preventDefault();

        if (preventDuplicateDataSending(subnav)) {
            return;
        }

        setActiveClass(selector, subnav);
        setPopoutDirection(subnav);

        Analytics.sendEvent({
            category: ANALYTICS_CATEGORY,
            action: `Open - ${selector.getAttribute(ANALYTICS_ACTION_ATTRIBUTE)}`,
            label: 'Event - Mouse enter',
        });
    });

    popoutMenuElements.forEach((popoutMenuElement: HTMLDivElement) => {
        popoutMenuElement.addEventListener('mouseenter', () => {
            eventClearTimeout(timeout);
        });

        popoutMenuElement.addEventListener('mouseleave', () => {
            eventClearTimeout(timeout);

            timeout = setTimeout(() => {
                removeActiveClass(selector, subnav);
                eventClearTimeout(timeout);

                Analytics.sendEvent({
                    category: ANALYTICS_CATEGORY,
                    action: `Close - ${selector.getAttribute(ANALYTICS_ACTION_ATTRIBUTE)}`,
                    label: 'Event - Timeout',
                });
            }, POPOUT_TIMEOUT);
        });
    });
};

const onClick = (selector: HTMLDivElement, subnav: HTMLDivElement): void => {
    const visibilityChangeEvent = new Event(EVENT.CATEGORY_MENU_VISIBILITY_CHANGE);

    selector.addEventListener('click', (e: Event): void => {
        e.preventDefault();

        if (preventDuplicateDataSending(subnav)) {
            return;
        }

        setActiveClass(selector, subnav);
        setPopoutDirection(subnav);

        subnav.dispatchEvent(visibilityChangeEvent);

        Analytics.sendEvent({
            category: ANALYTICS_CATEGORY,
            action: `Open - ${selector.getAttribute(ANALYTICS_ACTION_ATTRIBUTE)}`,
            label: 'Event - Click',
        });
    });
};

const onClickOutside = (selector: HTMLDivElement, subnav: HTMLDivElement): void => {
    document.addEventListener('click', (e: Event) => {
        const target: EventTarget | null = e.target;
        if (target instanceof HTMLElement && target !== selector && !selector.contains(target)) {
            removeActiveClass(selector, subnav);
        }
    });
};

const onItemClick = (selector: HTMLDivElement, subnav: HTMLDivElement): void => {
    const subnavItems = subnav.querySelectorAll<HTMLAnchorElement>('a');

    subnavItems.forEach((subnavItem: HTMLAnchorElement) => {
        subnavItem.addEventListener('click', (e: Event): void => {
            e.preventDefault();

            const target: EventTarget | null = e.target;
            if (target instanceof HTMLAnchorElement && target.getAttribute('href') !== '#') {
                window.open(target.getAttribute('href')?.toString(), '_self');
            }

            removeActiveClass(selector, subnav);
        });
    });
};

const preventDuplicateDataSending = (subnav: HTMLDivElement): boolean => {
    return subnav.classList.contains(IS_VISIBLE);
};

const eventClearTimeout = (timeout: ReturnType<typeof setTimeout>): void => {
    if (timeout) {
        clearTimeout(timeout);
    }
};

const removeActiveClass = (selector: HTMLDivElement, subnav: HTMLDivElement): void => {
    setTimeout(() => {
        selector.classList.remove(IS_ACTIVE);
        subnav.classList.remove(IS_VISIBLE, ...directions);
    }, 50);
};

const setActiveClass = (selector: HTMLDivElement, subnav: HTMLDivElement): void => {
    selector.classList.add(IS_ACTIVE);
    subnav.classList.add(IS_VISIBLE);
};

export const setPopoutDirection = (subnav: HTMLDivElement): void => {
    const direction: string[] = popoutDirection(subnav);
    const defaultDirection: string | null = subnav.getAttribute('data-direction');
    subnav.classList.remove(...directions);

    if (direction.length) {
        return subnav.classList.add(...direction);
    }

    if (defaultDirection && defaultDirection === 'bottom') {
        return subnav.classList.add(DIRECTION_BOTTOM);
    }

    return subnav.classList.add(DIRECTION_TOP);
};

const popoutDirection = (subnav: HTMLDivElement): string[] => {
    const position: string[] = [];
    const defaultDirection: string | null = subnav.getAttribute('data-direction');
    const triggerTop = defaultDirection === 'top' ? 140 : 40;
    const triggerBottom = defaultDirection === 'bottom' ? 100 : 0;
    const bounding: DOMRect = subnav.getBoundingClientRect();
    const isOut: ElementIsOutProperties = {
        top: bounding.top < triggerTop, // we have fixed header which needs to be taken in mind, so that it doesn't cover popout
        left: bounding.left < 0,
        bottom: bounding.bottom > (window.innerHeight || document.documentElement.clientHeight) - triggerBottom,
        right: bounding.right > (window.innerWidth || document.documentElement.clientWidth),
    };

    if (isOut.bottom) {
        position.push(DIRECTION_TOP);
    } else if (isOut.top) {
        position.push(DIRECTION_BOTTOM);
    }

    if (isOut.right) {
        position.push(DIRECTION_LEFT);
    } else if (isOut.left) {
        position.push(DIRECTION_RIGHT);
    }

    return position;
};

export default popoutMenu;
