import stickyElement from './features/sticky-element';
import popoutMenu from './features/popout-menu';
import { EVENT, IS_VISIBLE } from './constants';

function wioPopoutMenu(element: string, popoutElement: string): void {
    const container: HTMLDivElement | null = document.querySelector(element);
    const subnav: HTMLDivElement | null = document.querySelector(popoutElement);

    if (container && subnav) {
        popoutMenu(container, subnav);

        let firstClick = true;

        subnav.addEventListener(EVENT.CATEGORY_MENU_VISIBILITY_CHANGE, () => {
            if (subnav.classList.contains(IS_VISIBLE) && firstClick) {
                const activeElement: HTMLAnchorElement | null = subnav.querySelector('.selected');
                const subnavContainer: HTMLDivElement | null = subnav.querySelector('.subnav');

                if (activeElement && subnavContainer) {
                    const offset: number = activeElement.offsetTop;

                    subnavContainer.scrollTop = offset - 10;

                    firstClick = false;
                }
            }
        });
    }
}

// Sticky category dropdown
stickyElement('.js-wio__controls');
wioPopoutMenu('.js-popout-menu-categories', '.subnav__cnt');
wioPopoutMenu('.js-popout-menu-views', '.wio__controls-views');
